import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlogCard from "./blog-card";
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";
import SVGRoyalBlue from "./svg/royal-blue-svg";

const OurLatestBlogs = () => {
	const data = useStaticQuery(graphql`
		query {
			blogs: allWpPost(
				filter: { title: { ne: "Website Images" } }
				limit: 3
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					excerpt
					title
					slug
					blogFields {
						featuredImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
		}
	`);

	return (
		<div className="w-100 bg-secondary pb-5 position-relative">
			<SVGRoyalBlue
				style={{
					zIndex: 0,
					top: "-4rem",
					height: "auto",
					minHeight: "6rem",
				}}
				className="w-100 position-absolute"
			/>
			<Container style={{ zIndex: 1 }} className="position-relative">
				<Row className="justify-content-center">
					<Col className="pb-7">
						<h2 className="text-center text-white pt-5 pb-6 display-4">
							Our latest blog posts
						</h2>

						<Row className="justify-content-center">
							{" "}
							{data.blogs.nodes.map((post) => (
								<BlogCard
									boxShadow="0px 3px 50px #1C3554"
									title={post.title}
									excerpt={parse(post.excerpt)}
									uri={post.slug}
									image={
										post.blogFields.featuredImage?.localFile.childImageSharp
											.gatsbyImageData
									}
									imageAlt={post.blogFields.featuredImage.altText}
								/>
							))}
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default OurLatestBlogs;
