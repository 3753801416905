import * as React from "react";

const SVGLightBlue = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={1924.112}
		height={297.084}
		viewBox="0 0 1924.112 297.084"
		{...props}
	>
		<path
			id="Path_10"
			data-name="Path 10"
			d="M0,597.084V300c220.026,17.033,440.051,34.066,654.732,38.621s424.017-3.367,634.69-11.883S1712.1,309.111,1924.112,300V597.084Z"
			transform="translate(0 -300)"
			fill="#e4f3fb"
		/>
	</svg>
);

export default SVGLightBlue;
