import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NewsCard from "./news-card";
import NewsLatestArticle from "./news-latest-article";
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";

const LatestNews = () => {
	const data = useStaticQuery(graphql`
		query {
			articles: allWpArticle(
				sort: { fields: dateGmt, order: DESC }
				limit: 4
				skip: 1
			) {
				nodes {
					id
					title
					excerpt
					slug
					articleFields {
						featuredImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
		}
	`);

	return (
		<div className="w-100  pb-5 position-relative">
			<Container style={{ zIndex: 1 }} className="position-relative">
				<Row className="justify-content-center">
					<Col className="pb-7">
						<h2 className="text-primary text-center pt-5 pb-6 display-4">
							Latest News from
							<br className="d-none d-md-block" />
							 Friends of Eastbourne Hospital
						</h2>
						<NewsLatestArticle />
						<Row>
							{" "}
							{data.articles.nodes.map((post) => (
								<NewsCard
									title={post.title}
									excerpt={parse(post.excerpt)}
									uri={post.slug}
									image={
										post.articleFields.featuredImage?.localFile.childImageSharp
											.gatsbyImageData
									}
									imageAlt={post.articleFields.featuredImage.altText}
								/>
							))}
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default LatestNews;
