import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Hero from "../components/hero";
import OurLatestBlogs from "../components/our-latest-blogs";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import LatestNews from "../components/latest-news";
import LatestMagazine from "../components/latest-magazine";
import HowWeHelp from "../components/how-we-help";
import Banner from "../components/banner";
import VolunteeringCard from "../components/volunteering-card";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const IndexPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			blogs: allWpPost(limit: 3, sort: { fields: dateGmt, order: DESC }) {
				nodes {
					excerpt
					title
					slug
				}
			}
			events: allWpEvent(
				sort: { fields: eventFields___dateAndTime, order: ASC }
			) {
				nodes {
					title
					slug
					eventFields {
						dateAndTime
						heading
						extraDate
						extraDateAndTime
					}
				}
			}
			heroImg1: wpMediaItem(title: { eq: "Home-Hero-1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroImg2: wpMediaItem(title: { eq: "Home-Hero-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroImg3: wpMediaItem(title: { eq: "Home-Hero-3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroImg4: wpMediaItem(title: { eq: "Home-Hero-4" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroImg5: wpMediaItem(title: { eq: "Home-Hero-5" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			queenImgMain: wpMediaItem(title: { eq: "Shop-Deputy" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			voImg: wpMediaItem(title: { eq: "Volunteering-Opportunities-HP" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			hthlImg: wpMediaItem(title: { eq: "How-to-leave-a-legacy-HP" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			vbImg: wpMediaItem(title: { eq: "Volunteering-Benefits-HP" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			vcsImg: wpMediaItem(title: { eq: "Joan 1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			bvImg: wpMediaItem(title: { eq: "Becoming-A-Volunteer-sq" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			sLogo1: wpMediaItem(title: { eq: "Caffyns Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			sLogo2: wpMediaItem(title: { eq: "ECF Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			sLogo3: wpMediaItem(title: { eq: "J Heath and Son Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			sLogo4: wpMediaItem(title: { eq: "St Andrews Prep Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			sLogo5: wpMediaItem(title: { eq: "Eastbourne College Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			sLogo6: wpMediaItem(title: { eq: "Plan ahead Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			sLogo7: wpMediaItem(title: { eq: "Brewers Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			LogoImg: wpMediaItem(title: { eq: "icon-no-bg" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			Img1: wpMediaItem(title: { eq: "HG 1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img2: wpMediaItem(title: { eq: "HG 2" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img3: wpMediaItem(title: { eq: "HG 3" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img4: wpMediaItem(title: { eq: "HG 4" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img5: wpMediaItem(title: { eq: "HG 5" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img6: wpMediaItem(title: { eq: "HG 6" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img7: wpMediaItem(title: { eq: "HG 7" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img8: wpMediaItem(title: { eq: "HG 8" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img9: wpMediaItem(title: { eq: "HG 9" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img10: wpMediaItem(title: { eq: "HG 10" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img11: wpMediaItem(title: { eq: "HG 11" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img12: wpMediaItem(title: { eq: "HG 12" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img13: wpMediaItem(title: { eq: "HG 13" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img14: wpMediaItem(title: { eq: "HG 14" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img15: wpMediaItem(title: { eq: "HG 15" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img16: wpMediaItem(title: { eq: "HG 16" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg1 = data.heroImg1?.localFile.childImageSharp.gatsbyImageData;
	const heroImg2 = data.heroImg2?.localFile.childImageSharp.gatsbyImageData;
	const heroImg3 = data.heroImg3?.localFile.childImageSharp.gatsbyImageData;
	const heroImg4 = data.heroImg4?.localFile.childImageSharp.gatsbyImageData;
	const heroImg5 = data.heroImg5?.localFile.childImageSharp.gatsbyImageData;
	const hthlImg = data.hthlImg?.localFile.childImageSharp.gatsbyImageData;
	const heroImgMain =
		data.heroImgMain?.localFile.childImageSharp.gatsbyImageData;
	const queenImgMain =
		data.queenImgMain?.localFile.childImageSharp.gatsbyImageData;

	const responsive = {
		0: {
			items: 1,
		},
		767: {
			items: 1,
		},
		991: {
			items: 1,
		},
	};

	const items = [
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img1.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img2.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img3.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img4.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,

		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img5.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img6.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img7.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img8.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,

		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img9.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img10.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img11.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img12.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,

		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img13.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img14.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img15.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
		<Col xs={12} md={6} lg={3}>
			<GatsbyImage
				image={data.Img16.localFile.childImageSharp.gatsbyImageData}
				alt=""
				className="h-100"
			/>
		</Col>,
	];

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Welcome to Friends of Eastbourne Hospital"
				description="Supporting the patients, staff and visitors at the Eastbourne DGH by fundraising for additional equipment, amenities and services."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Welcome to Friends of Eastbourne Hospital",
					description:
						"Supporting the patients, staff and visitors at the Eastbourne DGH by fundraising for additional equipment, amenities and services.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<Hero
					events={data.events?.nodes}
					backgroundImage1={heroImg1}
					backgroundImageAlt1={data.heroImg1?.altText}
					backgroundImage2={heroImg2}
					backgroundImageAlt2={data.heroImg2?.altText}
					backgroundImage3={heroImg3}
					backgroundImageAlt3={data.heroImg3?.altText}
					backgroundImage4={heroImg4}
					backgroundImageAlt4={data.heroImg4?.altText}
					backgroundImage5={heroImg5}
					backgroundImageAlt5={data.heroImg5?.altText}
					backgroundImageMain={heroImgMain}
					backgroundImageAltMain={data.heroImgMain?.altText}
					title={
						<span>
							Welcome to
							<br className="d-none d-md-block" /> Friends of Eastbourne
							Hospital
						</span>
					}
					description={
						<span>
							Supporting the patients, staff and visitors at the Eastbourne DGH
							by
							<br /> fundraising for additional equipment, amenities and
							services.
						</span>
					}
				/>
				{/* <section className="pt-5  bg-light-blue">
					<Link to="/deputy-shop-manager-vacancy">
						<GatsbyImage
							image={queenImgMain}
							alt={data.queenImgMain?.altText}
							className="w-100 h-auto"
						/>
					</Link>
				</section> */}
				<section className="py-5 py-lg-7">
					<HowWeHelp />
				</section>
				<section className="pt-5">
					<LatestMagazine />
				</section>
				<section className="pt-10">
					<OurLatestBlogs />
				</section>
				<section className="py-5">
					<LatestNews />
				</section>
				<Banner text="Volunteers do not necessarily have the time; they just have the heart." />
				<section>
					<Container>
						<Row>
							<VolunteeringCard
								image={data.voImg?.localFile?.childImageSharp.gatsbyImageData}
								imageAlt={data.voImg?.altText}
								title="Volunteering Opportunities"
								url="/volunteering/volunteering-opportunities"
							/>
							<VolunteeringCard
								image={data.vbImg?.localFile?.childImageSharp.gatsbyImageData}
								imageAlt={data.vbImg?.altText}
								title="Volunteering Benefits"
								url="/volunteering/the-benefits-of-volunteering"
							/>
							<VolunteeringCard
								image={data.vcsImg?.localFile?.childImageSharp.gatsbyImageData}
								imageAlt={data.vcsImg?.altText}
								title="Volunteer Case Studies"
								url="/volunteering/volunteering-stories"
							/>
							<VolunteeringCard
								image={data.bvImg?.localFile?.childImageSharp.gatsbyImageData}
								imageAlt={data.bvImg?.altText}
								title="Becoming a Volunteer"
								url="/volunteering/becoming-a-volunteer"
							/>
						</Row>
					</Container>
				</section>
				<section className="pb-10 pt-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={12}>
								<Row className="">
									<Col xl={6}>
										<div style={{ boxShadow: "0px 3px 50px #00000029" }}>
											{" "}
											<GatsbyImage
												image={hthlImg}
												alt={data.hthlImg?.altText}
												className="w-100"
											/>
										</div>
									</Col>
									<Col className="py-0 my-0 " xl={6}>
										<div
											style={{ boxShadow: "0px 3px 50px #00000029" }}
											className="bg-white h-100 my-0 d-flex align-items-center justify-content-center position-relative "
										>
											<div className=" px-5 py-5 py-xl-0 text-center">
												<GatsbyImage
													image={
														data.LogoImg?.localFile?.childImageSharp
															.gatsbyImageData
													}
													alt={data.LogoImg?.altText}
													style={{ maxWidth: "80px" }}
												/>

												<h2 className=" text-primary  pt-5  pb-4 display-4">
													Legacies
													<br /> A gift in your Will
												</h2>
												<p className="text-start ">
													Leaving a legacy helps us to support the medical teams
													who help patients, their families and friends in more
													ways than you may imagine. A legacy is an amazing way
													for your generosity and kindness to last beyond your
													own lifetime.
												</p>
												<Button
													variant="outline-primary"
													className=" py-3 w-100  w-md-auto mt-4 mt-md-0 px-4  "
													to="/how-can-i-help/legacies"
													as={Link}
												>
													More information
												</Button>
											</div>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pt-5 pb-5 ">
					<Container>
						<Row className="justify-content-center">
							<Col xl={8}>
								<h2 className="text-center text-primary pb-5 display-5">
									Thank you to our supporters
								</h2>
								<Row className="justify-content-center gx-5 align-items-center">
									<Col className="mb-5" xs={6} md={3}>
										<GatsbyImage
											image={
												data.sLogo1.localFile.childImageSharp.gatsbyImageData
											}
											alt={
												data.sLogo1.localFile.childImageSharp.gatsbyImageData
											}
										/>
									</Col>
									<Col className="mb-5" xs={6} md={3}>
										<GatsbyImage
											image={
												data.sLogo2.localFile.childImageSharp.gatsbyImageData
											}
											alt={
												data.sLogo2.localFile.childImageSharp.gatsbyImageData
											}
										/>
									</Col>
									<Col className="mb-5" xs={6} md={3}>
										<GatsbyImage
											image={
												data.sLogo3.localFile.childImageSharp.gatsbyImageData
											}
											alt={
												data.sLogo3.localFile.childImageSharp.gatsbyImageData
											}
										/>
									</Col>
									<Col className="mb-5" xs={6} md={3}>
										<GatsbyImage
											image={
												data.sLogo4.localFile.childImageSharp.gatsbyImageData
											}
											alt={
												data.sLogo4.localFile.childImageSharp.gatsbyImageData
											}
										/>
									</Col>
									<Col className="mb-5" xs={6} md={3}>
										<GatsbyImage
											image={
												data.sLogo5.localFile.childImageSharp.gatsbyImageData
											}
											alt={
												data.sLogo5.localFile.childImageSharp.gatsbyImageData
											}
										/>
									</Col>
									<Col className="mb-5" xs={6} md={3}>
										<GatsbyImage
											image={
												data.sLogo6.localFile.childImageSharp.gatsbyImageData
											}
											alt={
												data.sLogo6.localFile.childImageSharp.gatsbyImageData
											}
										/>
									</Col>
									<Col className="mb-5" xs={6} md={3}>
										<GatsbyImage
											image={
												data.sLogo7.localFile.childImageSharp.gatsbyImageData
											}
											alt={
												data.sLogo7.localFile.childImageSharp.gatsbyImageData
											}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<Container fluid className="px-0 mx-0 object-cover">
						<Row>
							<Col>
								<h2 className="text-primary text-center pt-md-5 pb-md-6 pb-5 display-4">
									Image Gallery
								</h2>
							</Col>
						</Row>
						<Row className="justify-content-center pb-6 d-md-none">
							<AliceCarousel
								infinite={true}
								responsive={responsive}
								mouseTracking
								items={items}
								disableDotsControls
								autoPlay
								autoPlayInterval={2000}
							/>
						</Row>
						<Row className="g-0 d-none d-md-flex">
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img1.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img2.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img3.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img4.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
						</Row>
						<Row className="g-0 d-none d-md-flex">
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img5.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img6.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img7.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img8.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
						</Row>
						<Row className="g-0 d-none d-md-flex">
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img9.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img10.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img11.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img12.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
						</Row>
						<Row className="g-0 d-none d-md-flex">
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img13.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img14.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img15.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<GatsbyImage
									image={data.Img16.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default IndexPage;
