import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Button from "react-bootstrap/Button";
import Link from "./link";
import SVGLightBlue from "./svg/light-blue-svg";
import moment from "moment";
import Carousel from "react-bootstrap/Carousel";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Hero = ({
	title,
	description,
	backgroundImage1,
	backgroundImageAlt1,
	backgroundImage2,
	backgroundImageAlt2,
	backgroundImage3,
	backgroundImageAlt3,
	backgroundImage4,
	backgroundImageAlt4,
	backgroundImage5,
	backgroundImageAlt5,
	events,
	backgroundImageMain,
	backgroundImageAltMain,
}) => {
	const responsive = {
		0: {
			items: 2,
		},
		767: {
			items: 3,
		},
		991: {
			items: 4,
		},
	};

	const today = moment().startOf("day");

	const isEventUpcoming = (event) => {
		const startDate = moment(
			new Date(event.eventFields.dateAndTime.replace(" ", "T"))
		);
		const endDate = event.eventFields.extraDateAndTime
			? moment(new Date(event.eventFields.extraDateAndTime.replace(" ", "T")))
			: startDate;

		return endDate.isSameOrAfter(today);
	};

	const filteredEvents = events.filter(isEventUpcoming);

	const items = filteredEvents.map((event) => (
		<Col className="py-4  text-center">
			{event.eventFields.extraDate !== true && (
				<p className="inter-extrabold pb-0 mb-0 text-primary">
					{moment(
						new Date(event.eventFields.dateAndTime.replace(" ", "T"))
					).format("MMMM DD YYYY")}
				</p>
			)}
			{event.eventFields.extraDate === true && (
				<p className="inter-extrabold pb-0 mb-0 text-primary">
					{moment(
						new Date(event.eventFields.dateAndTime.replace(" ", "T"))
					).format("MMM DD")}{" "}
					-{" "}
					{moment(
						new Date(event.eventFields.extraDateAndTime.replace(" ", "T"))
					).format("MMM DD YYYY")}
				</p>
			)}
			<hr
				className="mx-auto bg-main-red py-0 my-1"
				style={{ opacity: 1, width: "4rem" }}
			/>

			<h3 style={{ minHeight: "48px" }} className="lato-bold fs-5 text-primary">
				{event.eventFields.heading}
			</h3>

			<Button
				as={Link}
				to={`/events/${event.slug}`}
				size="sm"
				variant="outline-primary px-3 mt-3 white-button"
			>
				Event Info
			</Button>
		</Col>
	));

	return (
		<div className="position-relative  " style={{ overflow: "hidden" }}>
			<div className="d-lg-none ">
				<BgImage
					image={getImage(backgroundImageMain)}
					alt={backgroundImageAltMain}
				>
					<div
						className=" w-100 h-100 position-absolute"
						style={{
							backgroundColor: "#0067A2B8",
							zIndex: -1,
						}}
					></div>{" "}
					<Container
						style={{ zIndex: 1, paddingBottom: "4rem" }}
						className="  "
					>
						<Row className="  text-center text-white py-5 px-3  align-items-center  ">
							<Col className="pl-0 ps-md-3">
								<h2 className=" mb-3 display-3" id="services-hero-title">
									{title}
								</h2>
								<p className="fs-5 mb-5">{description}</p>

								<Button
									style={{ border: "1px solid white" }}
									variant="primary"
									className=" py-3 w-100 w-md-auto me-4 text-white px-5 "
									as={Link}
									to="/about-us/aims-and-objectives"
								>
									What we do
								</Button>
								<Button
									variant="white"
									className=" py-3 w-100 text-primary w-md-auto me-4 mt-4 mt-md-0 px-4  "
									to="/how-can-i-help/donations"
									as={Link}
								>
									How can I help?
								</Button>
								<Button
									style={{ border: "1px solid white" }}
									variant="primary"
									className=" py-3  w-100 text-white w-md-auto mt-4 mt-md-0 px-4  "
									target="_blank"
									rel="noreferrer"
									href="https://givealittle.co/c/6czvd19U4LewzWDCAXv5FB"
								>
									Donate
								</Button>
							</Col>
						</Row>
					</Container>
				</BgImage>
			</div>

			<Carousel
				className="position-absolute d-none d-lg-block top-0  w-100"
				controls={false}
			>
				<Carousel.Item>
					<div
						className=" w-100 h-100 position-absolute"
						style={{
							backgroundColor: "#0067A2B8",
							zIndex: -1,
						}}
					></div>
					<GatsbyImage
						style={{ zIndex: -2 }}
						Tag="section"
						className="hero-services main-hero-image h-100  mb-3 mb-md-6 w-100  mb-lg-7"
						image={backgroundImageMain}
						alt={backgroundImageAltMain}
					></GatsbyImage>
				</Carousel.Item>
				<Carousel.Item>
					<div
						className=" w-100 h-100 position-absolute"
						style={{
							backgroundColor: "#0067A2B8",
							zIndex: -1,
						}}
					></div>
					<GatsbyImage
						loading="eager"
						style={{ zIndex: -2 }}
						Tag="section"
						className="hero-services main-hero-image  mb-3 mb-md-6 w-100  mb-lg-7"
						image={backgroundImage1}
						alt={backgroundImageAlt1}
					></GatsbyImage>
				</Carousel.Item>
				<Carousel.Item>
					<div
						className=" w-100 h-100  position-absolute"
						style={{
							backgroundColor: "#0067A2B8",
							zIndex: -1,
						}}
					></div>
					<GatsbyImage
						loading="eager"
						style={{ zIndex: -2 }}
						className="hero-services main-hero-image   mb-3 mb-md-6 w-100  mb-lg-7"
						image={backgroundImage2}
						alt={backgroundImageAlt2}
					></GatsbyImage>
				</Carousel.Item>
				<Carousel.Item>
					<div
						className=" w-100 h-100  position-absolute"
						style={{
							backgroundColor: "#0067A2B8",
							zIndex: -1,
						}}
					></div>
					<GatsbyImage
						loading="eager"
						style={{ zIndex: -2 }}
						className="hero-services main-hero-image   mb-3 mb-md-6 w-100   mb-lg-7"
						image={backgroundImage3}
						alt={backgroundImageAlt3}
					></GatsbyImage>
				</Carousel.Item>
				<Carousel.Item>
					<div
						className=" w-100 h-100  position-absolute"
						style={{
							backgroundColor: "#0067A2B8",
							zIndex: -1,
						}}
					></div>
					<GatsbyImage
						loading="eager"
						style={{ zIndex: -2 }}
						className="hero-services main-hero-image   mb-3 mb-md-6 w-100   mb-lg-7"
						image={backgroundImage4}
						alt={backgroundImageAlt4}
					></GatsbyImage>
				</Carousel.Item>
				<Carousel.Item>
					<div
						className=" w-100 h-100  position-absolute"
						style={{
							backgroundColor: "#0067A2B8",
							zIndex: -1,
						}}
					></div>
					<GatsbyImage
						loading="eager"
						style={{ zIndex: -2 }}
						className="hero-services main-hero-image   mb-3 mb-md-6 w-100  mb-lg-7"
						image={backgroundImage5}
						alt={backgroundImageAlt5}
					></GatsbyImage>
				</Carousel.Item>
			</Carousel>

			<Container
				style={{ zIndex: 1, paddingBottom: "4rem" }}
				className=" position-relative d-none d-lg-block home-hero-image "
			>
				<Row className="  text-center text-white h-100 px-3 py-5 py-md-7 pt-xl-8 align-items-center  ">
					<Col className="pl-0 ps-md-3">
						<h1 className=" mb-3 display-3" id="services-hero-title">
							{title}
						</h1>
						<p className="fs-5 mb-5">{description}</p>

						<Button
							style={{ border: "1px solid white" }}
							variant="primary"
							className=" py-3 w-100 w-md-auto me-4 text-white px-5 "
							as={Link}
							to="/about-us/aims-and-objectives"
						>
							What we do
						</Button>
						<Button
							variant="white"
							className=" py-3 me-4 w-100 text-primary w-md-auto mt-4 mt-md-0 px-4  "
							to="/how-can-i-help/donations"
							as={Link}
						>
							How can I help?
						</Button>
						<Button
							style={{ border: "1px solid white" }}
							variant="primary"
							className=" py-3  w-100 text-white w-md-auto mt-4 mt-md-0 px-4  "
							target="_blank"
							rel="noreferrer"
							href="https://givealittle.co/c/6czvd19U4LewzWDCAXv5FB"
						>
							Donate
						</Button>
					</Col>
				</Row>
			</Container>

			<div className="w-100 bg-light-blue pb-5 position-relative">
				<SVGLightBlue
					style={{
						zIndex: 0,
						top: "-4rem",
						height: "auto",
						minHeight: "5rem",
					}}
					className="w-100 position-absolute"
				/>
				<Container style={{ zIndex: 1 }} className="position-relative">
					<Row className="justify-content-center">
						<Col xl={8}>
							<h2 className="text-center text-primary py-5 fs-1">
								Join us at one our upcoming events
							</h2>

							<Row className="justify-content-center">
								<AliceCarousel
									infinite={true}
									responsive={responsive}
									mouseTracking
									items={items}
									disableDotsControls
									autoPlay
									autoPlayInterval={2000}
								/>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default Hero;
