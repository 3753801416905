import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Banner = ({ text }) => {
	const data = useStaticQuery(graphql`
		query {
			LogoImg: wpMediaItem(title: { eq: "icon-no-bg" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
		}
	`);
	return (
		<section
			style={{ overflow: "hidden" }}
			className="bg-primary position-relative mb-7"
		>
			<div
				style={{ zIndex: 0 }}
				className="position-absolute  top-50 start-50  translate-middle"
			>
				<GatsbyImage
					image={data.LogoImg?.localFile?.childImageSharp.gatsbyImageData}
					alt={data.LogoImg?.altText}
					style={{ maxWidth: "240px" }}
				/>
			</div>
			<Container
				style={{ zIndex: 1 }}
				className="py-5 py-md-7 position-relative"
			>
				<Row>
					<Col>
						<p className="text-white lato-italic display-4 text-center">
							"{text}"
						</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Banner;
