import React from "react";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";

const VolunteeringCard = ({ title, image, imageAlt, url }) => {
	return (
		<Col className="mb-5 mb-lg-0" md={6} xl={3}>
			<div
				style={{ overflow: "hidden" }}
				className="square-image position-relative"
			>
				<Link to={url}>
					<div>
						<div
							style={{ zIndex: 2 }}
							className="position-absolute top-50 start-50 translate-middle "
						>
							{" "}
							<h3 className=" display-5 text-decoration-none lato-italic  text-center ">
								{title}
							</h3>
						</div>

						<GatsbyImage
							style={{ opacity: 0.4 }}
							image={image}
							alt={imageAlt}
							className="position-absolute top-0 w-100"
						/>
					</div>
				</Link>
			</div>
		</Col>
	);
};

export default VolunteeringCard;
