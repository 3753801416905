import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Container } from "react-bootstrap";

const LatestMagazine = () => {
	const data = useStaticQuery(graphql`
		query {
			issue: allWpMagazineEdition(
				sort: { fields: magazineEditionFields___date, order: DESC }
				limit: 1
			) {
				nodes {
					id
					title
					magazineEditionFields {
						date
						fileDownload {
							mediaItemUrl
						}
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
					}
				}
			}
			LogoImg: wpMediaItem(title: { eq: "icon-no-bg" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
		}
	`);
	const issue = data.issue.nodes[0];
	return (
		<Container>
			<Row className="justify-content-center mb-6 align-items-center ">
				<Col xl={12}>
					<Row className=" mb-6 align-items-center h-100 ">
						<Col className="text-center text-xl-start" xs={12} lg={6} xl={5}>
							<a
								href={issue.magazineEditionFields.fileDownload.mediaItemUrl}
								target="_blank"
								rel="noreferrer"
							>
								<GatsbyImage
									className="mw-100 mx-auto mx-lg-0 mb-5 mb-lg-0"
									image={
										issue.magazineEditionFields.image?.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={issue.magazineEditionFields.image?.localFile.altText}
								/>
							</a>
						</Col>
						<Col lg={{ span: 6 }} xl={{ span: 6, offset: 1 }} className="  ">
							<div
								style={{ boxShadow: "0px 3px 50px #00000029" }}
								className="bg-white h-100 w-100 position-relative px-md-5 p-4 pt-md-6 pb-md-5"
							>
								<div className="position-absolute top-0 start-50 translate-middle">
									<GatsbyImage
										image={
											data.LogoImg?.localFile?.childImageSharp.gatsbyImageData
										}
										alt={data.LogoImg?.altText}
										style={{ maxWidth: "80px" }}
									/>
								</div>
								<h2 className=" text-primary text-center pt-3 pt-md-0 pb-4 display-4">
									Read our latest edition
								</h2>
								<p className="text-start ">
									We produce Hospitality magazine four times a year, filling it
									with news from the Friends, details of upcoming events and
									fundraisers, and interesting articles related to the community
									and the hospital, its staff or volunteers. Printed copies are
									available at various outlets in the hospital; members of the
									Friends receive their copy in the post; and the current issue,
									along with all archived issues, is available online here.
								</p>{" "}
								<div className="text-center mt-4">
									<a
										href={issue.magazineEditionFields.fileDownload.mediaItemUrl}
										target="_blank"
										rel="noreferrer"
									>
										<Button
											variant="primary"
											className=" text-center py-3 w-100 w-md-auto  text-white px-5 "
										>
											Read now
										</Button>
									</a>
								</div>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default LatestMagazine;
