import * as React from "react";

const SVGRoyalBlue = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={1920}
		height={250}
		viewBox="0 0 1920 250"
		{...props}
	>
		<path
			id="Path_11"
			data-name="Path 11"
			d="M0,500V250c209.423,23.867,418.844,47.733,650.667,50S1136.711,282.933,1352,271s391.64-16.467,568-21V500Z"
			transform="translate(0 -250)"
			fill="#0067a2"
		/>
	</svg>
);

export default SVGRoyalBlue;
